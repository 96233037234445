.header {
    padding: 1.25em 0;
	position: relative;
	z-index: 3;
}
.header__button, .header__button.dropdown.collapsed {
	padding: .5em 1em;
	border-radius: 4px;
	background: rgba(130, 130, 200, 0.2);
	color: white;
	cursor: pointer;
}
.header__menu {
	visibility: collapse;
}
.header__hamburger {
	margin: 0;
}
.header__hamburger__icon {
	display: block;
	width: 1.3rem;
	height: 1.3rem;
	margin: .1rem;

	path {
		// manipulate SVG fill color
		fill: white;
	}
}

.header__logo {
	display: block;
	width: 12rem;
	height: 2rem;

	outline: none;

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		display: block;
		background-repeat: no-repeat;
		background-position: left center;
		background-size: contain;
		background-image: url(../img/pcb_arts_logo_white.svg);
	}
}

.header__shop__icon {
	width: 16px;
	height: 16px;
}

.navbar__item {
	span {
		color: #009bff;
	}
}

.navbar__item__external__link:after{
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path fill="white" d="M14 3v2h4.586L9.293 14.293l1.414 1.414L20 6.414V11h2V3h-8z"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
	margin-bottom: 3px;
}

.navbar__item__external__link__blue:after{
	content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><path fill="#009bff" d="M14 3v2h4.586L9.293 14.293l1.414 1.414L20 6.414V11h2V3h-8z"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
	margin-bottom: 3px;
}

@include media-breakpoint-down(xl){
	.container-enlarger {
		max-width: 1360px;
	}
}


@include media-breakpoint-down(md) {
	.container-enlarger {
		max-width: auto;
	}

	.header__language {
		padding: 0 0.5rem;
	}
	.header__menu {
		visibility: visible;
	}
	.header__navbar {
		visibility: hidden;

		.navbar {
			// don't affect layout
			display: none;
		}
	}

	.social__navbar {
		display:none;
	}
}

@include media-breakpoint-down(xs) {
	.header__logo {
		width: 10.5rem;
		height: 1.75rem;
	}
}

