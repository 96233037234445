.navbar {
	margin: 0;
	padding: 0;
	text-align: center;
}

.navbar__item {
	display: inline-block;
	margin: 0 .75em;
	position: relative;
	cursor: pointer;
	outline:none;

	text-decoration: none;
	// inherit from parent
	color: inherit;
	
	// add underline
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		
		bottom: -.2em;
		height: 2px;
		background: white;
		transform: scaleX(0);
		transition: transform 200ms;
	}
	// show underline if hovered or focused
	&:hover:before, &:focus:before {
		transform: scaleX(1);
	}
	// color underline if active
	&.active:before {
		background-image: linear-gradient(90deg, $mediumblue, $babyblue);
		transform: scaleX(1);
	}

	// add dropdown marker
	&.dropdown:after {
		display: inline-block;
		margin-left: .25em;
		vertical-align: middle;
		content: "";
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
	}
	// disable underline if nav__item is a dropdown
	&.dropdown:before {
		visibility: hidden;
	}
}
.navbox {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 50vw;
	min-width: 20rem;
	max-width: 420px;
	
	z-index: 11;
	background: mix($navyblue, #808080, 70%);
	
	transform: translateX(0);
	visibility: visible;
	transition: visibility 0ms, transform 300ms;

	ul {
		list-style-type: none;
		padding: 0;
		overflow-y: auto;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	&.hidden {
		visibility: hidden;
		transform: translateX(-100%);
		transition: visibility 0ms 300ms, transform 300ms;
	}
}
.navbox__wrapper {
	// the wrapper allows the top container to be fixed
	position: relative;
	overflow-y: auto;
	padding: 1rem 0;
	width: 100%;
	height: 100%;
}
.navbox__logo {
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
	background-image: url(../img/pcb_arts_logo_white.svg);

	display: block;
	width: 11rem;
	height: 2rem;

	outline: none;
}
.navbox__top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 1rem;
	//height: 3.5rem;
	background: mix($navyblue, #808080, 70%);
	display: flex;
	align-items: center;
	z-index: 12;
}
.navbox__main {
	margin-top: 4rem;
}

.navbox__item {
	font-size: 1.25rem;
	cursor: pointer;

	a {
		padding: 1rem 2rem;
		display: block;
	}
	
	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}
}

.navbox__item--dropdown {
	padding: 1rem 2rem;

	&:after {
		content: "";
		display: inline-block;
		margin-left: .25em;
		vertical-align: middle;
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
		
		transform: rotate(90deg);
		transition: transform 200ms;
	}

	&.collapsed:after {
		transform: rotate(0deg);
	}
}
.navbox__submenu {
	list-style-type: none;
	padding: 0;
	font-size: 1rem;

	height: auto;
	overflow: hidden;

	a {
		display: block;
		padding: 1rem 2rem;
		outline: none;
		&:hover {
			background: rgba(255, 255, 255, 0.1);
		}
	}

	&.collapsed {
		height: 0;
	}
}
.navbox__close {
	font-size: 2em;
	padding: 0 1rem;
	cursor: pointer;
}

.navbox-overlay {
	z-index: 10;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;

	visibility: visible;
	background-color: rgba(0, 0, 0, 0.5);
	transition: visibility 0ms, background-color 300ms;

	&.hidden {
		background-color: rgba(0, 0, 0, 0);
		visibility: hidden;
		transition: visibility 0ms 300ms, background-color 300ms;
	}
}


