@import 'bootstrap/scss/bootstrap-grid.scss';

@import 'variables'; // should be imported first
@import 'fonts';
@import 'buttons';
@import 'scrollbar';
@import 'dropdown';
@import 'navigation';
@import 'header';
@import 'lazy';
@import 'footer';
@import 'bubbles';
@import 'cookies';

html {
	overflow-x:hidden;
}

body {
	position: relative;
    width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
    background: $navyblue;
    color: white;

	font-family: $bodytext;
	font-weight: 400;
	font-size: 18px;

	display: flex;
	flex-direction: column;
	
	// possible fix for laggy horizontal scrolling in Chrome on iPhone:
	overscroll-behavior: none;
}

body.no-scroll, html.has-lightbox body {
	overflow-y: hidden;
}

main {
	flex-grow: 1;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $headertext;
	font-weight: 600;
}

a {
	color: $mediumblue;
}

button {
	font-family: $bodytext;
	font-weight: 400;
}

.container--bubbles {
	position: relative;
}

.mb-6, .my-6 { margin-bottom: 6rem }
.mt-6, .my-6 { margin-top: 6rem }
.mb-8, .my-8 { margin-bottom: 8rem }
.mt-8, .my-8 { margin-top: 8rem }

.top {
	min-height: calc(100vh - 101px);
	width: 100%;
	display: flex;
	flex-direction: column;
}

.text-center {
	text-align: center;
}

.text-gradient {
	background-image: linear-gradient(90deg, $mediumblue, $babyblue);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: inline-block;
}

.text-usp-big{
	font-size:3em;
	font-weight:600;
}

.image {
    background-size: contain; /* Makes sure the entire image is visible */
    background-position: center center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling */
	width: 100%;
	height: 100%;
}

.load-image--lazy, .load-image--initial {
	position: relative;
	.load-image__spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -1rem;
		margin-left: -1rem;
		width: 2rem;
		height: 2rem;
		border: 4px solid rgba(255, 255, 255, 0.9);
		border-right-color: transparent;
		border-radius: 50%;
		animation: spinner-border 1s linear infinite;
	}
}
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -1rem;
	margin-left: -1rem;
	width: 2rem;
	height: 2rem;
	border: 4px solid rgba(255, 255, 255, 0.9);
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 1s linear infinite;

	&.hidden {
		display: none;
	}
}
@keyframes spinner-border {
	to { transform: rotate(360deg); }
}


.icon-grid {
	.icon-grid__image {
		width: 4rem;
		height: 4rem;
	}
	.icon-grid__box {
		margin-bottom: 1rem;
	}
}

.email {
	cursor: pointer;
}


.highlight-container {
	background: #f5f5f5;
	color: black;
	position: relative;
	overflow-y: visible;
	margin: 4rem 0;

	&::before, &::after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
	}

	&::before {
		top: -37px;
		border-left: 100vw solid transparent;
		border-bottom: 38px solid #f5f5f5;
	}
	&::after {
		border-right: 100vw solid transparent;
		border-top: 38px solid #f5f5f5;
		bottom: -37px;
	}

	strong {
		font-size: 4.5vw;
		line-height: 1.05;
		font-weight: 600;
		letter-spacing: -0.015em;
		padding: 20px;
		padding-right:100px;
		font-family: $headertext;
	}
}

.image_lower_right_corner{
    position: absolute;
    width: 150px;
    right: 20px;
    bottom: 20px;
}

.service-image{
	width:100%;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
}


// Success Stories Start
.card-link-wrapper:link{
    text-decoration: none;
}

.usp-link-wrapper{
	cursor:auto;
	background-image:-webkit-gradient(linear,left top,right top,from(#232559),to(#323569)) !important;
	background-image:linear-gradient(90deg,#232559,#323569) !important;
}

.flex-container{
    display:flex;
}

.card-wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    background-image:linear-gradient(90deg,#1c79f9,#28cbea);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
}

.card-wrapper:hover {
    box-shadow: rgba(40, 203, 234, 0.5) 0px 0px 29px 0px;
    transform: scale(1.05);
    cursor:pointer;
  }

.card {
    overflow: hidden;
    width: 100%;
}

.card-header {
    background-size: cover;
    height: 200px;
    position: relative;
  }
  
  .card-header:before {
    content: "";
    display: block;
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
  }
  
  .card-header h3 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
  
  .card-body {
    padding: 15px;
    font-size: 0.85em;
  }

  .card-body > h3, p {
    color: #FFFFFF;
    text-decoration: none;
  }

  .card-body > h3 {
    font-size: 1.5em;
  }

  .card-footer__left{
      width:50%;
      float:left;
  }

.card-footer__image > img {
    width: 50%;
    height: auto;
}

.streamocean-card{
    background: url('../img/products/success-stories/fish-classification-streamocean.png') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.enabl-card{
    background: url('../img/products/success-stories/enabl-overview.jpeg') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.norskdata-card{
    background: url('../img/products/success-stories/edgekit-cabinet-norskdata.jpeg') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.exocore-card{
	background: url('../img/products/success-stories/ExoCore.png') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.roboception-card{
	background: url('../img/products/success-stories/rc_visard_ng.jpg') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.symbiotic-card{
	background: url('../img/products/success-stories/fpga_switch.png') center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


// Success Stories End 


// Slider Configuration

	.slider {
		height: 75pt;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		cursor: default;
		user-select: none;
		touch-action: none;
	}

	.slider:before {
		left: 0;
		top: 0;
	}
	.slider:after,
	.slider:before {
		background: linear-gradient(
			to right,
			#000123 0%,
			hsla(0, 0%, 100%, 0) 100%
		);
		content: "";
		height: 75pt;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	.slider:after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}
	.slider:after,
	.slider:before {
		background: linear-gradient(
			to right,
			#000123 0%,
			hsla(0, 0%, 100%, 0) 100%
		);
		content: "";
		height: 75pt;
		position: absolute;
		width: 200px;
		z-index: 2;
	}

	// Index Slider
	.slider-index {
		height: 75pt;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		cursor: default;
		user-select: none;
		touch-action: none;
	}

	.slider-index:before {
		left: 0;
		top: 0;
	}
	.slider-index:after,
	.slider-index:before {
		content: "";
		height: 75pt;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	.slider-index:after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}
	.slider-index:after,
	.slider-index:before {
		content: "";
		height: 75pt;
		position: absolute;
		width: 200px;
		z-index: 2;
	}

	.slide-track-1 {
		animation: scroll-l 30s linear infinite;
		display: flex;
		width: 4000px;
	}

	.slide {
		height: 75pt;
		width: 250px;
		display: flex;
		align-items: center;
		text-align: center;
	}
	.slide img {
		width: 180px;
		padding: 1pc;
		vertical-align: middle;
		margin: 0 auto;
		display: inline-block;
		max-width: 100%;
		height: auto;
	}

	@keyframes scroll-l {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-250px * 8));
		}
	}
	@keyframes scroll-r {
		100% {
			transform: translateX(0);
		}
		0% {
			transform: translateX(calc(-250px * 8));
		}
	}

// END Configure

@include media-breakpoint-down(md) {
	html, body {
		font-size: 16px;
	}

	.service-image{
		position: relative;
	}

	.card-wrapper{
		margin: 0.5em;
	}
}

@include media-breakpoint-down(sm) {
	html, body {
		font-size: 14px;
	}
	.image--mobile {
		height: 350px;
	}
	.services-change-order {
		order: 1;
	}
	.image_lower_right_corner{
		width:75px !important;
	}
	.highlight-container{
		strong {
			padding-right:50px !important;
		}
	}
	
}

@include media-breakpoint-down(xs) {
	html, body {
		font-size: 13px;
	}

}
