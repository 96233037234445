$lazy_animation_length: 500ms;
$lazy_animation_delay: 200ms;

[scroll-effect="fade-in"] {

	visibility: hidden;
	opacity: 0;
	transform: translate3d(0, 2rem, 0);

	&.fade-in {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		visibility: visible;
		transition: opacity $lazy_animation_length, transform $lazy_animation_length;
	}
}
@for $i from 0 to 6 {
	[scroll-effect-delay="#{$i}"] {
		transition-delay: $i * $lazy_animation_delay !important;
	}
}

