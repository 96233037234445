.dropdown {
	position: relative;

	&.collapsed .dropdown__menu {
		visibility: collapse;
	}
}

.dropdown__menu {
	position: absolute;
	top: 2rem;
	left: 0;
	border-radius: .25rem;
	padding: .5rem 0;
	background: rgb(230, 230, 233);

	color: $navyblue;
	// safe space to prevent immediate mouseout events
	&:before {
		content: "";
		position: absolute;
		top: -1.5em;
		height: 1.5em;
		width: 100%;
		left: 0;
		right: 0;
	}
	
	a {
		text-decoration: none;
		// inherit from parent
		color: inherit;
	}
}

.dropdown__menu__item {
	padding: .5em 1em;
	text-align: left;
	white-space: nowrap;
	
	display: flex;
	align-items: center;


	&:hover, &:focus {
		background: white;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	}
}

.dropdown--lang {
	background: white;
	color: $navyblue;
	display: inline-flex;
	align-items: center;
    outline: none;
	
	> span {
		text-transform: uppercase;
	}

	.dropdown__menu {
		right: 0;
		left: initial;
		top: 3rem;
	}

	/*&:after {
		// Dropdown marker
		display: inline-block;
		margin-left: .5em;
		content: "";
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
	}*/
}
.dropdown--lang__flag {
	width: 1.5em;
	height: 1.5em;
	
	display: inline-block;
	
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;

	&[data-lang="de"] {
		background-image: url('../../node_modules/svg-country-flags/svg/de.svg');
	}

	&[data-lang="en"] {
		background-image: url('../../node_modules/svg-country-flags/svg/gb.svg');
	}
}
