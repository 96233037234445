.container--bubbles {
	position: relative;
}
.bubble {
	z-index: -1;
	width: 82vw;
	height: 82vw;
	position: absolute;
	// circle center according to top/left coordinates
	transform: translate3d(-50%, -50%, 0);

	background-size: 100%;
}
.bubble--green {
	background-image: url("../img/bubble_green.svg");
}
.bubble--purple {
	background-image: url("../img/bubble_purple.svg");
}
.bubble--blue {
	background-image: url("../img/bubble_blue.svg");
}

