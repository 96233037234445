.footer {
	margin-top: 7em;
	padding: 2em 0;
	background-color: $lightgrey;
    z-index: 2;
    position: relative;

	a {
		text-decoration: none;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		top: -37px;
		border-left: 100vw solid transparent;
		border-bottom: 38px solid #f5f5f5;
	}
}

.gradient-divider {
	background: linear-gradient(to right, rgba(28, 121, 249, 0.0) 0%, $mediumblue 35%, $babyblue 70%, rgba(40, 203, 234, 0.0) 100%);
	height: 1px;
	border: 0px;
}

.footer__lead {
	color:$navyblue;

	h5 {
		color: $navyblue-superlight;
	}
}

.footer__main {
	font-size: 0.825em;
	color: $navyblue;

	img {
		width: 9rem;
	}

	p {
		color: $navyblue-superlight;
	}
}

.footer__entry {
	h5 {
		color: $navyblue;
	}

	p {
		color: black;
		font-family: Proxima Nova,Arial,sans-serif;
    	font-weight: 600;
		font-size:0.83em;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		font-size: 0.825em;

		li {
			color: $navyblue-superlight;
			padding: 3px 0;
			text-decoration: none;
			a {
				text-decoration: none;
			}
		}
	}
}


.footer__copyright {
	font-size: 0.825em;
	color: $navyblue-superlight;
	a {
		color: $navyblue-superlight;
	}
}

.footer__social {
	font-size: 0.825em;

	img {
		vertical-align: middle;
		margin-top: -3px;
		margin-right: 2px;
	}
	.footer__social__icon--linkedin {
		width: 1.3em;
		height: 1.3em;
	}
	.footer__social__icon--twitter {
		width: 1.5em;
		height: 1.5em;
	}
}


.footer__legal {
	font-size: 0.825em;
	color: $navyblue-superlight;
	text-align: right;

	ul {
		list-style-type: none;

		li {
			padding: 0 .5em;
			display: inline-block;
		}
	}
}

@include media-breakpoint-down(md) {
	.calendly-badge-widget {
		display: none;
	}
	.footer__copyright {
		order: 1;
		text-align: center;
	}
	.footer__social {
		text-align: center;
	}
}

