.button {
	display: inline-block;
	position: relative;
	margin: .5rem 0;
	cursor: pointer;
	background-image: linear-gradient(90deg,$mediumblue, $babyblue);
	-webkit-transition: background-image 0.2s ease-in-out;
	transition: background-image 0.2s ease-in-out;
	transition: background 250ms;
	height: 2.25rem;
	line-height: 2.25rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;

	border: none;
	border-radius: 1.125rem; // height / 2

	text-decoration: none;
	color: white;
	font-size: 1rem;
	text-transform: uppercase;

	&:hover{
		background-image: linear-gradient(90deg,$mediumblue, $babyblue);
	}

	&:focus{
		outline: none;
	}
}

.button--outline {
	padding: 2px;
	line-height: calc(2.25rem - 4px);
	
	span {
		padding: 0 1.25rem;
		height: 100%;
		background: $navyblue;
		transition: background 250ms;
		display: inline-block;
		border-radius: calc(1.125rem - 2px);
	}

	&:hover span {
		background: transparent;
	}
}

