.cookie-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: rgba(0, 0, 0, 0.75);
	z-index: 9999; // above calendly badge (z-index: 9998)

	&.hidden {
		display: none;
	}
}

.cookie-banner {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	left: 0;
	width: 100vw;
	//background: mix($navyblue, #808080, 70%);
	background: white;
	color: $navyblue;
	padding: 2rem 0;

	&.hidden {
		display: none;
	}
}

.cookie-banner__buttons {
	margin-top: 2rem;
	text-align: right;
}

.cookie-banner h2{
	font-size: 2rem;
}

@include media-breakpoint-down(md) {
	.cookie-banner {
		padding: 1rem 0;
	}
}
