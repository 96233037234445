html {
	// supported by firefox 64+
	scrollbar-color: $mediumblue mix($navyblue, white, 90%);
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	width: .25rem;
	min-width: 4px;
}

::-webkit-scrollbar-track {
	background: mix($navyblue, white, 90%);
}

::-webkit-scrollbar-thumb {
	background-color: $mediumblue;
	border-radius: 2px;
}
