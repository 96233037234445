// Generell

$base-path: '../font/';
$weights-header: (200, 300, 400, 600, 700, 800, 900);

@for $i from 1 to length($weights-header)+1 {

    $name: '#{$base-path}/ProximaNova-#{nth($weights-header, $i)}';

    @font-face {
        font-family: 'Proxima Nova';
        font-weight: nth($weights-header, $i);
        font-style: normal;
        src: url('#{$name}.woff2') format('woff2'),
            url('#{$name}.woff') format('woff');

		// render fallback font immediately, swap when loaded
		font-display: swap;
    }
}

$weights-body: (400, 500, 600, 700, 800);

@for $i from 1 to length($weights-body)+1 {

    $name: '#{$base-path}/BalooThambi2-#{nth($weights-body, $i)}';

    @font-face {
        font-family: 'Baloo Thambi 2';
        font-weight: nth($weights-body, $i);
        font-style: normal;
        src: url('#{$name}.woff2') format('woff2'),
            url('#{$name}.woff') format('woff');

		font-display: swap;
    }
}

